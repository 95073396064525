import { ErrorService } from "services/error-service";
import { Models } from "models/core";
import { autoinject, containerless } from "aurelia-framework";
import { bindable, bindingMode } from "aurelia-framework";
import { DepartmentService } from "services/department-service";
import { EventAggregator } from "aurelia-event-aggregator";
import { ToastService } from "services/toast-service";
import { Router } from "aurelia-router";

@containerless
@autoinject
export class DepartmentFormInlineNew {
  @bindable customerId: number;
  private department: Models.Department;

  constructor(
    private departmentService: DepartmentService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService
  ) {}

  createDepartment() {
    this.department.CustomerId = this.customerId;
    this.departmentService
      .post(this.department)
      .then(res => {
        this.eventAggregator.publish("departmentListReset", 1);
        this.eventAggregator.publish("departmentFormNewClose", 1);        
        this.toastService.showSuccess("department.created");
        this.department = new Models.Department;
      })
      .catch(err => this.errorService.handleError(err));
  }

  cancel(){
    this.eventAggregator.publish("departmentFormNewClose", 1);
  }
}
