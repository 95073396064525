import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { DimensionClassService } from "services/dimension-class-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class dimensionClassDetail {
  private dimensionclass: Models.DimensionClass;

  constructor(
    private dimensionClassService: DimensionClassService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.dimensionClassService
      .get(params.Id)
      .then(dimensionClass => {
        this.dimensionclass = dimensionClass;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateDimensionClass() {
    this.dimensionClassService
      .put(this.dimensionclass, this.dimensionclass.Id)
      .then(res => {
        this.eventAggregator.publish("dimensionClassListReset", 1);
        this.toastService.showSuccess("dimensionclass.updated");
        this.router.navigateToRoute("dimension-class-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteDimensionClass() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.dimensionClassService
          .delete(this.dimensionclass.Id)
          .then(res => {
            this.eventAggregator.publish("dimensionClassListReset", 1);
            this.toastService.showSuccess("dimensionclass.deleted");
            this.router.navigateToRoute("dimension-class-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      );    
  }
  
  gotoParentView(){
    this.eventAggregator.publish("dimensionClassListReset", 1);
    this.router.navigateToRoute("dimension-class-list");   
  }
}
