import { autoinject, bindable } from 'aurelia-framework';
import { ErrorService } from 'services/error-service';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { DesignTypeDrawingService } from 'services/design-type-drawing-service';
import { SystemFileStorageService } from 'services/system-file-storage-service';
import { FileAttachmentModels } from 'models/FileAttachmentModels';
import { ToastService } from 'services/toast-service';

@autoinject()
export class FileUploader {
  @bindable public fileId: number;
  @bindable public designTypeId: number;
  @bindable public showDownload = true;
  @bindable public fileOnly = false;
  @bindable public disableDelete = false;

  private selectedFiles: File;
  protected isUploading = false;
  protected fileMetaData: FileAttachmentModels.FileAttachment;

  constructor(
    private element: Element,
    private drawingService: DesignTypeDrawingService,
    private systemFileService: SystemFileStorageService,
    private errorService: ErrorService,
    private deleteDialogService: DeleteDialogService,
    private toaster: ToastService
  ) {
    this.element = element;
  }

  bind() {
    if (this.fileId && this.showDownload) {
      void this.getFileMetaData(this.fileId);
    }
  }

  private async getFileMetaData(systemFileId: number) {
    try {
      this.fileMetaData = await this.systemFileService.getMetaData(systemFileId);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  public async updateFilesSelect() {
    try {
      if (!this.selectedFiles[0]) {
        return;
      }
      this.isUploading = true;
      const file = await this.drawingService.upload(this.designTypeId, this.selectedFiles[0]);

      const event = new CustomEvent('file-uploaded', {
        detail: { fileId: file.SystemFileId, designTypeId: this.designTypeId },
        bubbles: true,
      });

      this.element.dispatchEvent(event);
      this.isUploading = false;

      void this.getFileMetaData(file.SystemFileId);
      this.toaster.showSuccess('general.fileUploaded');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  public async downloadFile() {
    await this.systemFileService.download(this.fileId, true);
  }

  public async deleteFile() {
    await this.deleteDialogService.confirmBeforeDelete(async () => {
      await this.drawingService.deleteByFileId(this.fileId);
      const event = new CustomEvent('file-deleted', {
        detail: { fileId: this.fileId, designTypeId: this.designTypeId },
        bubbles: true,
      });
      this.element.dispatchEvent(event);
      this.toaster.showSuccess('general.fileDeleted');
    });
  }
}
