import { autoinject, bindable, containerless } from 'aurelia-framework';

@containerless
@autoinject
export class DynamicTableCreateView {
  @bindable buttonText = '';
  @bindable isCreating = false;
  @bindable onEdit: () => void;
  @bindable header = '';

  constructor() {}
}
