import { autoinject, bindable, observable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ProductService } from 'services/product-service';

@autoinject
export class DisinfectionTypeForm {
  @bindable
  @observable
  private disinfectionType: Models.DisinfectionType;
  disinfectionTypeChanged(disinfectionType: Models.DisinfectionType) {
    if (disinfectionType?.Id) {
      void this.setProductName(disinfectionType.ProductId);
    }
  }

  protected articleNo = '';

  constructor(
    private productService: ProductService,
    private errorService: ErrorService
  ) {}

  protected setProductId(event: { detail: { value: string } }) {
    const productId = Number(event.detail.value);
    this.disinfectionType.ProductId = productId;
    void this.setProductName(productId);
  }

  private async setProductName(productId: number) {
    try {
      if (productId) {
        const product = await this.productService.get(productId);
        this.disinfectionType.Name = product.Name;
        this.disinfectionType.NameEn = product.Name;
        this.disinfectionType.NameEs = product.Name;
        this.articleNo = product.ArticleNo;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
