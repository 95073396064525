import { autoinject } from 'aurelia-dependency-injection';
import { AnalysisService } from 'services/analysis-service';
import { Router } from 'aurelia-router';
import { DialogController } from 'aurelia-dialog';
import { ConfirmDialog } from '../confirm/confirm-dialog';
import { bindable } from 'aurelia-templating';
import { ErrorService } from 'services/error-service';

export class AnalysisMatch {
  Probability: number;
  UsedInNets: number;
  AnalysisReference: string;
  ArchiveInformation: string;
  AnalysisId: number;
  AnalysisVariantId: number;
  Circumference: number;
  SignedDate: Date;
  Comment: string;
  SignedBy: string;
}

@autoinject()
export class AnalysisLookupDialog {
  protected analyses: AnalysisMatch[] = [];
  protected result: AnalysisMatch[] = [];

  @bindable protected analysisSearchTerm: string;
  protected analysisSearchTermChanged(searchTerm: string) {
    searchTerm = searchTerm.trim();
    if (!searchTerm) {
      this.result = this.analyses;
    } else {
      this.result = this.analyses.filter((a) => {
        return (
          a.AnalysisReference?.toLowerCase().includes(searchTerm) ||
          a.ArchiveInformation?.toLowerCase().includes(searchTerm)
        );
      });
    }
  }

  constructor(
    protected analysisService: AnalysisService,
    protected router: Router,
    protected dialog: DialogController,
    protected confirm: ConfirmDialog,
    protected errorService: ErrorService
  ) {}

  protected activate(params: { netId: number; slot: number }) {
    void this.findAnalysis(params.netId, params.slot);
  }

  protected linkAnalysis(analysisVariantId: number) {
    void void this.confirm.confirm(
      'dialog.areYouSure',
      'analysis.confirmAnalysisChoice',
      (confirmed) => {
        if (confirmed) {
          void this.dialog.close(
            true,
            this.analyses.find((a) => a.AnalysisVariantId == analysisVariantId)
          );
        }
      },
      { yes: { enabled: true }, cancel: { enabled: true } }
    );
  }

  protected openAnalysis(analysisId: number) {
    window.open(`/#/analysis/details/${analysisId}/design`, '__blank');
  }

  protected async findAnalysis(netId: number, slot: number) {
    try {
      const res = await this.analysisService.getAnalysisForNet(netId, slot);
      this.analyses = res;
      this.result = res;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
