import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { DesignTypeService } from 'services/design-type-service';
import { DesignTypeSideRopeCombinationService } from 'services/design-type-side-rope-combination-service';

@autoinject
export class DesignTypeSideRopeCombinationNew {
  private designTypeSideRopeCombination: Models.DesignTypeSideRopeCombination = new Models.DesignTypeSideRopeCombination();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private designTypeService: DesignTypeService,
    private designTypeSideRopeCombinationService: DesignTypeSideRopeCombinationService
  ) {}


  private activate(params){
    if(params.Id) {
      this.designTypeSideRopeCombination.DesignTypeId = parseInt(params.Id)
    }
  }

  private createDesignTypeSideRopeCombination() {
    this.designTypeSideRopeCombinationService
      .post(this.designTypeSideRopeCombination)
      .then((res) => {
        this.eventAggregator.publish('designTypeSideRopeCombinationListReset', 1);
        this.toastService.showSuccess('designTypeSideRopeCombination.created');
        this.router.navigateToRoute('design-type-side-rope-combination-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
