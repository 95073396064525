import { autoinject, bindable } from 'aurelia-framework';
import { FileAttachmentModels } from 'models/FileAttachmentModels';
import './dropdown-attachments-list.scss';

@autoinject
export class DropdownAttachmentsList {
  @bindable() protected attachments: FileAttachmentModels.FileAttachment[];
  @bindable() protected numberOfAttachments?: number;
  @bindable() protected id: number;
  @bindable() protected getAttachments?: ({ id }: { id: number }) => Promise<FileAttachmentModels.FileAttachment[]>;
  @bindable() protected downloadAttachment?: ({ id, fileId }: { id: number; fileId: number }) => Promise<void>;

  protected showAttachmentsForId: number;

  protected download(e: Event, file: FileAttachmentModels.FileAttachment) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    e.preventDefault();
    e.cancelBubble = true;
    void this.downloadAttachment({ id: this.id, fileId: +file.Id });
  }

  protected async showAttachments(e: Event) {
    this.attachments = [];
    e.stopPropagation();
    e.preventDefault();

    this.showAttachmentsForId = +this.id;

    this.attachments = await this.getAttachments({ id: this.id });

    if (!this.attachments?.length) return;

    const tableOpener = (e.target as HTMLElement).closest('.table-dropdown');
    const posOfDropdownBox = tableOpener.getBoundingClientRect();
    const dropdownContent = tableOpener.getElementsByClassName('content')[0] as HTMLElement;
    const dropDownToggler = tableOpener.getElementsByClassName('toggle')[0] as HTMLElement;

    dropdownContent.style.width = '0px';
    dropdownContent.style.height = '0px';

    setTimeout(() => {
      dropdownContent.style.width = 'auto';
      dropdownContent.style.height = 'auto';

      const contentSizes = dropdownContent.getBoundingClientRect();
      let offsetPos = posOfDropdownBox.height;
      // Move dropdown up if it's too close to the bottom of the screen
      if (document.body.clientHeight - posOfDropdownBox.top < 400) {
        offsetPos = -contentSizes.height;
      }

      const w = dropDownToggler.getBoundingClientRect().width || 0;
      dropdownContent.style.width = 'auto';
      dropdownContent.style.visibility = 'hidden';
      dropdownContent.style.top = posOfDropdownBox.top + offsetPos + 'px';
      dropdownContent.style.left = posOfDropdownBox.left - contentSizes.width + w + 'px';
      dropdownContent.style.visibility = 'visible';
    }, 100);
  }
}
