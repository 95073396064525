import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, computedFrom } from "aurelia-framework";
import { Router, activationStrategy } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { DesignTypeSideRopeCombinationService } from 'services/design-type-side-rope-combination-service';
import { DesignTypeService } from 'services/design-type-service';

@autoinject
export class DesignTypeSideRopeCombinationList {
  private tableData: Array<Models.DesignTypeSideRopeCombination>;
  private activeTableRow: number;
  private filterByDesignTypeId: any = null;

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private designTypeService: DesignTypeService,
    private designTypeSideRopeCombinationService: DesignTypeSideRopeCombinationService
  ) {}

  private activate(params) {
    if (params.Id) {
      this.activeTableRow = params.Id;
    } else {
      this.activeTableRow = 0;
    }

    if(params.DesignTypeId){
      this.filterByDesignTypeId = parseInt(params.DesignTypeId);
    }

  }

  private attached(params) {
    this.eventAggregator.subscribe('designTypeSideRopeCombinationListReset', (value) => {
      this.activeTableRow = null;
      //return this.updateView();
    });
    this.updateView();
  }
  

  private updateView() {
    this.designTypeSideRopeCombinationService
      .getAll('?$expand=DesignType')
      .then((res) => {
        this.tableData = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }
 
  private updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }

  private setFilteredByDesignTypeId(value){
    if(value === this.filterByDesignTypeId || !value){
      return;
    }
    this.filterByDesignTypeId = value;

    const newUrl = this.updateQueryStringParameter(window.location.href, 'DesignTypeId', this.filterByDesignTypeId);
    history.replaceState(null, null, newUrl);

  }

  @computedFrom('tableData', 'filterByDesignTypeId')
  get filteredTableData() {
    if(!this.tableData){
      return [];
    }
    if (this.filterByDesignTypeId) {
      return this.tableData.filter(dt => dt.DesignTypeId === parseInt(this.filterByDesignTypeId));
    }

    return this.tableData;
  }

  private addNew(){
    this.router.navigateToRoute('design-type-side-rope-combination-new',  { Id: this.filterByDesignTypeId });
  }

  private gotoDetailView(id) {
    this.activeTableRow = id;
    this.router.navigateToRoute('design-type-side-rope-combination-detail', { Id: id });
  }




}
