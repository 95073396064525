import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Models } from "models/core";
import { autoinject, containerless } from "aurelia-framework";
import { bindable, bindingMode } from "aurelia-framework";
import { DepartmentService } from "services/department-service";
import { EventAggregator } from "aurelia-event-aggregator";
import { ToastService } from "services/toast-service";
import { Router } from "aurelia-router";

@containerless
@autoinject
export class DepartmentFormInlineEdit {
  @bindable department: Models.Department;

  constructor(
    private departmentService: DepartmentService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private deleteDialogService: DeleteDialogService
  ) {}

  updateDepartment() {
    delete this.department['Sites'];

    this.departmentService
      .put(this.department, this.department.Id)
      .then(res => {
        this.eventAggregator.publish("departmentListReset", 1);
        this.eventAggregator.publish("departmentFormEditClose", 1);
        this.toastService.showSuccess("department.updated");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteDepartment() {    
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.departmentService
          .delete(this.department.Id)
          .then(res => {
            this.eventAggregator.publish("departmentFormEditClose", 1);
            this.eventAggregator.publish("departmentListReset", 1);    
            this.toastService.showSuccess("department.deleted");
          })
          .catch(err => this.errorService.handleError(err));
      }
    );  
  }

  cancel() {
    this.eventAggregator.publish("departmentFormEditClose", 1);
    this.eventAggregator.publish("departmentListReset", 1);    
  }
}
