import { autoinject } from 'aurelia-dependency-injection';
import { Router } from 'aurelia-router';
import { DialogController, DialogService } from 'aurelia-dialog';
import { ConfirmDialog } from '../confirm/confirm-dialog';
import { ErrorService } from 'services/error-service';
import { ServiceStationService } from 'services/service-station-service';
import { Models } from 'models/core';
import { NetService } from 'services/net-service';
import './storage-selection-dialog.scss';
import { UserService } from 'services/user-service';
import { ApplicationState } from 'lib/state';
import { connectTo } from 'aurelia-store';
import { ToastService } from 'services/toast-service';
import { StorageSetTimeDialog } from 'components/storage/storage-set-time-dialog';
import { StorageDetailsDialog } from 'components/storage/storage-details-dialog';
import { StorageHistoryService } from 'services/storage-history-service';

type StorageLocation = {
  serviceStationName: string;
  serviceStationId: number;
  storageName: string;
  storageId: number;
};

@autoinject()
@connectTo()
export class StorageSelectionDialog {
  private netId: number;

  protected serviceStations: Models.ServiceStation[];
  protected selectedServiceStationId: number;

  protected storages: Models.StorageViewPipeline[];
  protected filteredStorages: Models.StorageViewPipeline[];

  private currentStorageLocation: StorageLocation;

  private state: ApplicationState;

  protected isLoading = false;

  constructor(
    protected serviceStationService: ServiceStationService,
    protected userService: UserService,
    protected netService: NetService,
    protected router: Router,
    protected dialog: DialogController,
    private dialogService: DialogService,
    protected confirm: ConfirmDialog,
    protected errorService: ErrorService,
    private toastService: ToastService,
    private storageHistoryService: StorageHistoryService
  ) {}

  protected activate(params: { netId: number; storageLocation: StorageLocation }) {
    this.netId = params.netId;
    this.currentStorageLocation = params.storageLocation;
  }

  protected attached() {
    void this.setup();
  }

  private async setup() {
    let serviceStationId: number | undefined;

    if (this.currentStorageLocation?.serviceStationId) {
      serviceStationId = this.currentStorageLocation.serviceStationId;
    } else {
      serviceStationId = this.getUserServiceStation();
    }

    if (serviceStationId) {
      await this.getServiceStationStorages(serviceStationId);
    }

    this.selectedServiceStationId = serviceStationId;
  }

  protected onSearchChanged(search: KeyboardEvent) {
    const inputElement = search.target as HTMLInputElement;
    const searchValue = inputElement.value?.trim();
    if (!searchValue) this.filteredStorages = this.storages;

    this.filteredStorages = this.storages.filter((x) => {
      return x.Name.toLowerCase().includes(searchValue.toLowerCase());
    });
  }

  protected getUserServiceStation() {
    if (!this.state?.user) return;
    return this.state?.user.ServiceStationId;
  }

  protected async getServiceStations() {
    this.serviceStations = await this.serviceStationService.getAllCached();
    return this.serviceStations;
  }

  protected async getServiceStationStorages(serviceStationId: number) {
    this.clearStorages();
    this.isLoading = true;
    this.storages = await this.netService.getStorageView(serviceStationId, null, null, null);
    if (!this.storages) this.storages = [];
    this.filteredStorages = this.storages;
    this.isLoading = false;
  }

  private clearStorages() {
    this.filteredStorages = undefined;
    this.storages = undefined;
  }

  protected onSelectedServiceStationChanged(selectedId: number) {
    this.selectedServiceStationId = selectedId;
    void this.getServiceStationStorages(selectedId);
  }

  protected editDetails() {
    const pipelineItem = {
      Id: this.netId,
      NetIdentifier: '',
      CustomerName: '',
      ServiceStationStorageId: null,
      SortIndex: null,
    } as Models.StorageViewPipelineItem;

    void this.dialogService
      .open({
        viewModel: StorageSetTimeDialog,
        model: {
          item: pipelineItem,
        },
      })
      .whenClosed(async () => {
        const item = await this.netService.getStorageViewItemDetails(this.netId);
        this.currentStorageLocation.storageId = item.ServiceStationStorageId;
      });
  }

  protected addToStorage(storageId: number) {
    void void this.confirm.confirm(
      'dialog.areYouSure',
      'storage.addToStorage',
      async (confirmed) => {
        if (confirmed) {
          try {
            await this.netService.updateStorageView(this.netId, storageId, 1);
            this.currentStorageLocation = {
              serviceStationId: this.selectedServiceStationId,
              serviceStationName: this.serviceStations.find((x) => x.Id === this.selectedServiceStationId)?.Name,
              storageId: storageId,
              storageName: this.storages.find((x) => x.ServiceStationStorageId === storageId)?.Name,
            };
            this.toastService.showSuccess('storage.addedToStorage');
            this.editDetails();
          } catch (error) {
            this.errorService.handleError(error);
          }
        }
      },
      {
        yes: { enabled: true },
        cancel: { enabled: true },
      }
    );
  }

  protected removeFromStorage() {
    void void this.confirm.confirm(
      'dialog.areYouSure',
      'storage.removeFromStorage',
      async (confirmed) => {
        if (confirmed) {
          try {
            await this.netService.updateStorageView(this.netId, null, 1);
            this.currentStorageLocation = undefined;
            this.toastService.showSuccess('storage.removedFromStorage');
            this.editDetails();
          } catch (error) {
            this.errorService.handleError(error);
          }
        }
      },
      {
        yes: { enabled: true },
        cancel: { enabled: true },
      }
    );
  }
}
