import { autoinject, bindable, observable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ProductService } from 'services/product-service';

@autoinject
export class DetergentTypeForm {
  @bindable
  @observable
  private detergentType: Models.DetergentType;
  detergentTypeChanged(detergentType: Models.DetergentType) {
    if (detergentType?.Id) {
      void this.setProductName(detergentType.ProductId);
    }
  }

  protected articleNo = '';

  constructor(
    private productService: ProductService,
    private errorService: ErrorService
  ) {}

  protected setProductId(event: { detail: { value: string } }) {
    const productId = Number(event.detail.value);
    this.detergentType.ProductId = productId;
    void this.setProductName(productId);
  }

  private async setProductName(productId: number) {
    try {
      if (productId) {
        const product = await this.productService.get(productId);
        this.detergentType.Name = product.Name;
        this.detergentType.NameEn = product.Name;
        this.detergentType.NameEs = product.Name;
        this.articleNo = product.ArticleNo;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
