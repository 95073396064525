import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { DesignTypeService } from "services/design-type-service";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class DesignTypeNew {
  private designtype: Models.DesignType = new Models.DesignType();

  constructor(
    private designTypeService: DesignTypeService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createDesignType() {
    this.designTypeService
      .post(this.designtype)
      .then(res => {
        this.eventAggregator.publish("designTypeListReset", 1);
        this.toastService.showSuccess("designtype.created");
        this.router.navigateToRoute("design-type-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
