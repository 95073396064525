import { Models } from 'models/core';
import { DesignTypeService } from 'services/design-type-service';
import { ErrorService } from 'services/error-service';
import { autoinject } from 'aurelia-framework';
import './design-type-drawing-management.scss';
import { Event } from 'models';

type FileEvent = {
  designTypeId: number;
  fileId: number;
};

@autoinject
export class DesignTypeDrawingManagement {
  protected isLoaded = false;
  protected designs: Models.DesignType[] = [];
  constructor(private netDesignService: DesignTypeService, private errorService: ErrorService) {}

  protected attached() {
    void this.getNetDesigns();
  }

  protected onFileDeleted(event: Event<FileEvent>) {
    const { designTypeId } = event.detail;

    this.designs = this.designs.map((d) => {
      if (d.Id === designTypeId && d.Drawing) {
        d.Drawing.SystemFileId = null;
      }
      return d;
    });
  }

  protected onFileUploaded(event: Event<FileEvent>) {
    const { designTypeId, fileId } = event.detail;

    this.designs = this.designs.map((d) => {
      if (d.Id === designTypeId && d.Drawing) {
        d.Drawing.SystemFileId = fileId;
      }
      return d;
    });
  }

  protected async getNetDesigns() {
    try {
      this.designs = await this.netDesignService.getAll('?$expand=Drawing');
      this.isLoaded = true;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
