import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { DesignTypeService } from "services/design-type-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class designTypeDetail {
  private designtype: Models.DesignType;

  constructor(
    private designTypeService: DesignTypeService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.designTypeService
      .get(params.Id)
      .then(designType => {
        this.designtype = designType;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateDesignType() {
    this.designTypeService
      .put(this.designtype, this.designtype.Id)
      .then(res => {
        this.eventAggregator.publish("designTypeListReset", 1);
        this.toastService.showSuccess("designtype.updated");
        this.router.navigateToRoute("design-type-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteDesignType() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.designTypeService
          .delete(this.designtype.Id)
          .then(res => {
            this.eventAggregator.publish("designTypeListReset", 1);
            this.toastService.showSuccess("designtype.deleted");
            this.router.navigateToRoute("design-type-list");
          })
          .catch(err => this.errorService.handleError(err));
      }
    );    
  }
  gotoParentView(){
    this.eventAggregator.publish("designTypeListReset", 1);
    this.router.navigateToRoute("design-type-list");   
  }
}
