import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { DetergentTypeService } from 'services/detergent-type-service';
import { ErrorService } from 'services/error-service';

@autoinject
export class DetergentTypeList {
  tableData: Models.DetergentType[];
  activeTableRow: number;

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private detergentTypeService: DetergentTypeService
  ) {}

  activate(params: { Id: number }) {
    if (params.Id) {
      this.activeTableRow = params.Id;
    } else {
      this.activeTableRow = 0;
    }
  }

  private async updateView() {
    try {
      this.tableData = await this.detergentTypeService.getAll('?$orderby=Name&$expand=Product');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async attached() {
    this.eventAggregator.subscribe('detergentTypeListReset', () => {
      this.activeTableRow = null;
      return this.updateView();
    });

    await this.updateView();
  }

  gotoDetailView(id: number) {
    this.activeTableRow = id;
    this.router.navigateToRoute('detergent-type-detail', { Id: id });
  }
}
