import { FrameworkConfiguration, PLATFORM } from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
  config.globalResources([
    PLATFORM.moduleName('./dynamic-table'),
    PLATFORM.moduleName('./dynamic-table-item'),
    PLATFORM.moduleName('./dynamic-table-row'),
    PLATFORM.moduleName('./dynamic-table-action-button'),
    PLATFORM.moduleName('./dynamic-table-data-view'),
    PLATFORM.moduleName('./dynamic-table-create-view'),
  ]);
}
